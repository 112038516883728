import { Box, Container, Heading, Text, useColorMode } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import ImgTextModule from '../../components/elements/ImgTextModule';
import FadeInAnimation from '../../components/UX/FadeInAnimation';
import MagicText from '../../components/UX/MagicText';
import TeamDuo from '../../components/TeamDuo';
import TeamTri from '../../components/TeamTri';
import KontaktFooter from '../../components/KontaktFooter';
const IndexPage = () => {
  const { setColorMode } = useColorMode();

  useEffect(() => {
    setColorMode('light');
  });

  return (
    <>
      <Helmet>
        <title>KDW | Nils ykstra</title>
        <meta
          name='description'
          content='kdw HR bietet Kunden Dienstleistungen von der Personalvermittlung, über qualifizierte Arbeitnehmerüberlassung bis hin zu Management- und Fördermittelberatung im gesamten Personalmanagementbereich. Arbeitnehmer finden bei uns langfristige Karriereoptionen genauso wie neue Herausforderungen vornehmlich im Industrie-, Gewerbe- und Officesegment.'
        />
        <link rel='canonical' href='https:/kdw-hr.de' />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
        <link rel="manifest" href="/site.webmanifest"/>
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5"/>
        <meta name="msapplication-TileColor" content="#da532c"/>
        <meta name="theme-color" content="#ffffff"/>
      </Helmet>

      {/* CONTAINER MAGICTEXT */}

      <Container minW='100%' mt={0.5} p={0}>
        <MagicText
          text={
            'Wir begeistern Mitarbeiter und Unternehmen mit einem Personalmanagement, neu gedacht, flexibel, nachhaltig, sicher und innovativ.'
          }
          quoteOrigin='Silvio Köhler'
          fontSize={{ md: '6xl', base: '3xl' }}
          coloranimtext={'#ffaa00'}
          scrollLength={'250vh'}
          gap={{ md: 0, base: 0 }} //gap between words
          videoSrc='Silvio.mp4'
          scrollStartFactor={-0.1}
          scrollEndFactor={0.5}
          textColor={'brand.yellow'}
          showProgressCircle={true}
          progressColor={'brand.yellow'}
          showProgressLine={true}
          progressLineColor={'brand.violet'}
        />
      </Container>

      <Box bg='brand.gray.1000'>
        <Container variant='layoutContainer' py={32} px={8}>
          <FadeInAnimation
            threshold={0.1}
            delay={0}
            duration={0.7}
            initialX={0}
            initialY={10}
          >
            <Heading color='brand.violet' fontSize={{ base: '4xl', lg: '6xl' }}>
              Silvio Köhler
            </Heading>
          </FadeInAnimation>
          <FadeInAnimation
            threshold={0.1}
            delay={0.3}
            duration={0.7}
            initialX={0}
            initialY={10}
          >
            <Text my={4} fontSize={'4xl'} color='brand.green'>
              Individuelle Förderstrategien
            </Text>
          </FadeInAnimation>

          <FadeInAnimation
            threshold={0.1}
            delay={0.3}
            duration={1}
            initialX={0}
            initialY={10}
          >
            <Text
              maxW={{ base: '100%', lg: '70%', xl: '50%' }}
              my={8}
              fontSize={'lg'}
              color='brand.gray.500'
            >
              Ich arbeite als Betriebswirt seit Anfang der 2000er im HR-Bereich
              bundesweit als Geschäftsentwickler, Führungskraft und Stratege im
              Personalmanagement. 1978 in der Nähe der Müritz geboren, lebe ich
              seit 20 Jahren in Lübeck. Die hanseatischen Werte und Tugenden
              sind die Grundlage meines täglichen Handelns. Meine Spezialgebiete
              sind die Bereiche Fördermittel-, Talent- & Retention-Management.
            </Text>
          </FadeInAnimation>
          <FadeInAnimation
            threshold={0.1}
            delay={0.3}
            duration={1}
            initialX={0}
            initialY={10}
          >
            <Text
              maxW={{ base: '100%', lg: '70%', xl: '50%' }}
              my={8}
              fontSize={'lg'}
              color='brand.gray.500'
            >
              Ich bin davon überzeugt, dass „kdw HR“ als Impulsgeber und
              Sparringspartner für unsere Kunden den Wandel am HR-Markt
              vorantreiben wird.
            </Text>
          </FadeInAnimation>
          <FadeInAnimation
            threshold={0.1}
            delay={0.3}
            duration={0.7}
            initialX={0}
            initialY={10}
          >
            <Text
              maxW={{ base: '100%', lg: '70%', xl: '50%' }}
              my={8}
              fontSize={'lg'}
              color='brand.gray.500'
            >
              <strong>Ihr Silvio Köhler</strong>
            </Text>
          </FadeInAnimation>
        </Container>
      </Box>
      <TeamTri />
      <KontaktFooter />
    </>
  );
};

export default IndexPage;
